<template>
    <div class="ranger-container">
        <div class="start-date" @click="showDateRanger">{{startDate}}</div>
        <div class="per">至</div>
        <div class="end-date" @click="showDateRanger">{{endDate}}</div>

        <nut-calendar
                :is-visible.sync="isVisible"
                :default-value="date"
                type="range"
                :start-date="null"
                :end-date="null"
                :animation="`nutSlideUp`"
                @close="switchPickerClose('isVisible')"
                @choose="setChooseValue"
        >
        </nut-calendar>
    </div>
</template>

<script>
  export default {
    name: 'dateRangerV1',
    data () {
      return {
        isVisible: false,
        date: null
      }
    },
    props: {
        orginDate: {
            type: String,
            default: ''
        },
    },
    created() {
        let orginDate=this.$props.orginDate;
        if (orginDate){
            this.date=orginDate.split(",");
        }


    },
      computed: {
      startDate () {
        let date = ''
        if (this.date != null) {
          date = this.date[0]
        }
        return date
      },
      endDate () {
        let date = ''
        if (this.date != null) {
          date = this.date[1]
        }
        return date
      },

    },
    methods: {
      setChooseValue (param) {
        this.date = [...[param[0][3], param[1][3]]]
        this.$emit('chooseDate', this.date)
      },
      switchPickerClose () {

      },
      showDateRanger () {
        this.isVisible = true
      }
    }
  }
</script>

<style scoped lang="scss">
    .ranger-container {
        display: flex;
        align-items: center;

        .start-date, .end-date {
            flex: 1;
            height: 36px;
            border: #D1D1D1 solid 1px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            font-size: 14px;
            color: #939393;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .per {
            width: 20px;
            font-size: 14px;
            color: #333333;
            margin: 0 5px;
        }

    }
</style>